<script>
export default {
  props: {
    icon: {
      type: String,
      default: "info-circle",
    },
    content: {
      type: String,
      default: "",
    },
    placement: {
      type: String,
      default: "top",
    },
    top: {
      type: String,
      default: null,
    },
    offset: {
      type: String,
      default: "20",
    },
    color: {
      type: String,
      default: "text-gray-600",
    },
  },
};
</script>

<template>
  <VTooltip :distance="10" :placement="placement">
    <slot name="icon">
      <font-awesome-icon
        :icon="['fas', icon]"
        class="text-sm"
        :class="[color]"
      />
    </slot>
    <template #popper>
      <slot>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="content" />
      </slot>
    </template>
  </VTooltip>
</template>

<style>
.v-popper__popper {
  max-width: 100vw;
}
.v-popper--theme-tooltip .v-popper__inner {
  background: inherit;
  padding: 0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--vc-gray-300);
  background: white !important;
  padding: 10px 20px;
  color: var(--vc-gray-600);
  font-family: HKNova, ui-serif;
  font-size: 14px;
  max-width: 400px;
}

.v-popper--theme-tooltip .v-popper__arrow-outer {
  border-color: white;
}

.v-popper--theme-tooltip .v-popper__arrow-container {
  display: none;
}
</style>
